
                                @import "./src/kit/styles/config/_include";
                            
@import "kit/styles/global/global.scss" ;
html, body {
    height: 100%;
}
.page__main {
    display: flex;
    flex-direction: column;
}
.errorpage {
    min-height: 100%;
    font-family: $font-family-base;
    background: url(./img/error-bg.svg) center / cover no-repeat;
    padding-top: 2rem;
    padding-bottom: 4rem;
    flex-grow: 1;
    &-title {
        font-size: 3.125rem;
        margin-bottom: 1.875rem;
        font-weight: $font-weight-medium;
        color: $color-black;
        text-align: center;
    }
    &-text {
        font-size: 1.875rem;
        color: $color-gray-1000;
        margin-bottom: 1.875rem;
        font-weight: $font-weight-medium;
        text-align: center;
    }
    &-img {
        width: 37.5rem;
        height: 25rem;
        max-width: 100%;
        max-height: calc(100vh - 25rem);
        margin-bottom: 3.75rem;
        background: bottom center / contain no-repeat;
    }

    &-500 &-img {
        background-image: url(./img/error-500.svg);
    }
    &-404 &-img {
        background-image: url(./img/error-404.svg);
    }
    &-btn {
        width: 12.5rem;
        height: 3.75rem;
        font-weight: $font-weight-medium;
    }
    &-logo {
        width: 9.75rem;
        height: 1.5rem;
        background: url(../../components/Header/img/logo-black.svg) center / contain no-repeat;
    }
    &-content {
        width: 100%;
        padding: 0 1.875rem;
    }
    &-header {
        padding: 1.875rem;
        width: 100%;
        margin-bottom: auto;
    }
    @include media-breakpoint-down(md) {
        background: url(./img/error-bg-mobile.svg) right bottom / auto no-repeat;
        &-title {
            font-size: 2.5rem;
        }
        &-text {
            font-size: 1rem;
        }
        &-img {
            width: calc(100% - 2rem);
            height: 14.375rem;
            min-height: 14.375rem;
            background-size: contain;
        }
        &-btn {
            width: calc(100% - 4rem);
            margin-top: auto;
            margin-bottom: 1.875rem;
        }
    }
}
