@import "../styles/vuexy/plugins/forms/form-validation";

$primary-color: (
    'lighten-4': lighten($primary, 20%),
    'lighten-3': lighten($primary, 15%),
    'lighten-2': lighten($primary, 10%),
    'lighten-1': lighten($primary, 5%),
    'base': $primary,
    'darken-1': darken($primary, 5%),
    'darken-2': darken($primary, 10%),
    'darken-3': darken($primary, 15%),
    'darken-4': darken($primary, 20%)
);
$secondary-color: (
    'lighten-4': lighten($secondary, 20%),
    'lighten-3': lighten($secondary, 15%),
    'lighten-2': lighten($secondary, 10%),
    'lighten-1': lighten($secondary, 5%),
    'base': $secondary,
    'darken-1': darken($secondary, 5%),
    'darken-2': darken($secondary, 10%),
    'darken-3': darken($secondary, 15%),
    'darken-4': darken($secondary, 20%)
);

// #4839EB
//
$success-color: (
    'base': $success,
);
$info-color: (
    'base': $info,
);
$warning-color: (
    'base': $warning,
);
$danger-color: (
    'base': $danger,
);
$colors: (
    'primary': $primary-color,
    'secondary': $secondary-color,
    'success': $success-color,
    'info': $info-color,
    'warning': $warning-color,
    'danger': $danger-color
);

@each $color_name, $color in $colors {
    @each $color_type, $color_value in $color {
        input:focus ~ .bg-#{$color_name} {
            box-shadow: 0 0 0 0.075rem $white, 0 0 0 0.21rem $color_value !important;
        }

        // Custom Checkbox & Radio
        @if ($color_name != 'light' and $color_name != 'black' and $color_name != 'white') {
            .form-check-#{$color_name} {
                .form-check-input {
                    &:checked {
                        border-color: $color_value;
                        background-color: $color_value;
                    }
                    &:not(:disabled) {
                        &:checked,
                        &:focus {
                            border-color: $color_value;
                            box-shadow: 0 2px 4px 0 rgba($color_value, 0.4);
                        }
                    }
                }
            }
        }
    }
}
.form-control {
    color: $color-black-soft;
    height: $input-height;
    font-size: .75rem;
    line-height: 2;
    &:focus {
        border-color: $color-blue;
    }
    &::placeholder {
        color: $color-black-soft;
    }
}
.form-label {
    font-size: 0.75rem;
}

$danger: $color-brand;

.form-item {
    &.error {
        input,
        input:focus,
        textarea {
            border-color: $danger !important;
        }
        span.error {
            display: block;
            margin-top: .5rem;
            font-size: .75rem;
        }
    }

    &--required {
        > label::after {
            content: "*";
            color: $color-brand;
            margin-left: 5px;
        }
    }
}

form span.error {
    font-size: 0.75rem;
    letter-spacing: -0.02em;
}