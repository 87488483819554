$webfont-icons: () !default;

$webfont-icons: map-merge($webfont-icons, (
    
        "arrow-down": ("IconFont", "\ea01"),
    
        "arrow-down-in-circle": ("IconFont", "\ea02"),
    
        "arrow-left": ("IconFont", "\ea03"),
    
        "arrow-left-long": ("IconFont", "\ea04"),
    
        "arrow-right": ("IconFont", "\ea05"),
    
        "arrow-right-long": ("IconFont", "\ea06"),
    
        "arrow-short": ("IconFont", "\ea07"),
    
        "arrow-up": ("IconFont", "\ea08"),
    
        "arrow-up-in-circle": ("IconFont", "\ea09"),
    
        "calendar": ("IconFont", "\ea0a"),
    
        "cart": ("IconFont", "\ea0b"),
    
        "check": ("IconFont", "\ea0c"),
    
        "checkbox-on": ("IconFont", "\ea0d"),
    
        "clock": ("IconFont", "\ea0e"),
    
        "combined": ("IconFont", "\ea0f"),
    
        "credit-card": ("IconFont", "\ea10"),
    
        "cross": ("IconFont", "\ea11"),
    
        "cup": ("IconFont", "\ea12"),
    
        "currency-circle-rub": ("IconFont", "\ea13"),
    
        "filters": ("IconFont", "\ea14"),
    
        "fire": ("IconFont", "\ea15"),
    
        "heart": ("IconFont", "\ea16"),
    
        "heart-fill": ("IconFont", "\ea17"),
    
        "heart-simple": ("IconFont", "\ea18"),
    
        "info": ("IconFont", "\ea19"),
    
        "life-buoy": ("IconFont", "\ea1a"),
    
        "list": ("IconFont", "\ea1b"),
    
        "list-wide": ("IconFont", "\ea1c"),
    
        "lock": ("IconFont", "\ea1d"),
    
        "mail": ("IconFont", "\ea1e"),
    
        "receipt": ("IconFont", "\ea1f"),
    
        "reload": ("IconFont", "\ea20"),
    
        "search": ("IconFont", "\ea21"),
    
        "sign-in": ("IconFont", "\ea22"),
    
        "sign-out": ("IconFont", "\ea23"),
    
        "speed": ("IconFont", "\ea24"),
    
        "target": ("IconFont", "\ea25"),
    
        "user": ("IconFont", "\ea26"),
    
        "user-icon": ("IconFont", "\ea27"),
    
        "voronka": ("IconFont", "\ea28"),
    
        "warning-circle": ("IconFont", "\ea29"),
    
));


@mixin webfont-icon($name) {
    line-height: 1;
    $icon: map-get($webfont-icons, $name);
    &:before {
        font-family: nth($icon, 1) !important;
        font-style: normal;
        font-weight: normal !important;
        vertical-align: top;
        content:  nth($icon, 2);
    }
}

@mixin webfont-icon-full($name) {
    $icon: map-get($webfont-icons, $name);
    font-family: nth($icon, 1) !important;
    font-style: normal;
    font-weight: normal !important;
    vertical-align: top;
    content:  nth($icon, 2);
}


@mixin webfont-init() {
    @font-face {
        font-family: "IconFont";
    src: url("../IconsFont/fonts/IconFont.eot?1722682239705");
        src: url("../IconsFont/fonts/IconFont.eot?#iefix") format("embedded-opentype"), url("../IconsFont/fonts/IconFont.woff?1722682239705") format("woff"), url("../IconsFont/fonts/IconFont.ttf?1722682239705") format("truetype"), url("../IconsFont/fonts/IconFont.svg?1722682239705#IconFont") format("svg");
    }

    
        .icon-arrow-down {
            @include webfont-icon('arrow-down');
        }
    
        .icon-arrow-down-in-circle {
            @include webfont-icon('arrow-down-in-circle');
        }
    
        .icon-arrow-left {
            @include webfont-icon('arrow-left');
        }
    
        .icon-arrow-left-long {
            @include webfont-icon('arrow-left-long');
        }
    
        .icon-arrow-right {
            @include webfont-icon('arrow-right');
        }
    
        .icon-arrow-right-long {
            @include webfont-icon('arrow-right-long');
        }
    
        .icon-arrow-short {
            @include webfont-icon('arrow-short');
        }
    
        .icon-arrow-up {
            @include webfont-icon('arrow-up');
        }
    
        .icon-arrow-up-in-circle {
            @include webfont-icon('arrow-up-in-circle');
        }
    
        .icon-calendar {
            @include webfont-icon('calendar');
        }
    
        .icon-cart {
            @include webfont-icon('cart');
        }
    
        .icon-check {
            @include webfont-icon('check');
        }
    
        .icon-checkbox-on {
            @include webfont-icon('checkbox-on');
        }
    
        .icon-clock {
            @include webfont-icon('clock');
        }
    
        .icon-combined {
            @include webfont-icon('combined');
        }
    
        .icon-credit-card {
            @include webfont-icon('credit-card');
        }
    
        .icon-cross {
            @include webfont-icon('cross');
        }
    
        .icon-cup {
            @include webfont-icon('cup');
        }
    
        .icon-currency-circle-rub {
            @include webfont-icon('currency-circle-rub');
        }
    
        .icon-filters {
            @include webfont-icon('filters');
        }
    
        .icon-fire {
            @include webfont-icon('fire');
        }
    
        .icon-heart {
            @include webfont-icon('heart');
        }
    
        .icon-heart-fill {
            @include webfont-icon('heart-fill');
        }
    
        .icon-heart-simple {
            @include webfont-icon('heart-simple');
        }
    
        .icon-info {
            @include webfont-icon('info');
        }
    
        .icon-life-buoy {
            @include webfont-icon('life-buoy');
        }
    
        .icon-list {
            @include webfont-icon('list');
        }
    
        .icon-list-wide {
            @include webfont-icon('list-wide');
        }
    
        .icon-lock {
            @include webfont-icon('lock');
        }
    
        .icon-mail {
            @include webfont-icon('mail');
        }
    
        .icon-receipt {
            @include webfont-icon('receipt');
        }
    
        .icon-reload {
            @include webfont-icon('reload');
        }
    
        .icon-search {
            @include webfont-icon('search');
        }
    
        .icon-sign-in {
            @include webfont-icon('sign-in');
        }
    
        .icon-sign-out {
            @include webfont-icon('sign-out');
        }
    
        .icon-speed {
            @include webfont-icon('speed');
        }
    
        .icon-target {
            @include webfont-icon('target');
        }
    
        .icon-user {
            @include webfont-icon('user');
        }
    
        .icon-user-icon {
            @include webfont-icon('user-icon');
        }
    
        .icon-voronka {
            @include webfont-icon('voronka');
        }
    
        .icon-warning-circle {
            @include webfont-icon('warning-circle');
        }
    
}

