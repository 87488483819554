// Variables
// ------------------------------
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
// Functions
@import '../vuexy/bootstrap-extended/functions'; // Vuexy core function
@import '~bootstrap/scss/functions'; // Bootstrap core function
@import 'functions';

// Variables
@import '../vuexy/bootstrap-extended/variables'; // Vuexy core variable
@import '~bootstrap/scss/variables'; // Bootstrap core variable
@import 'variables';

@import "colors";
@import "grid";
@import "typograph";


// Mixins
@import '../vuexy/bootstrap-extended/mixins'; // Vuexy core mixins
@import '~bootstrap/scss/mixins'; // Bootstrap core mixins
@import "mixins";

$border-width:                1px;
$border-width-sm:             $border-width;
$border-width-lg:             $border-width * 2;
$border-color:                $color-gray-500;

$border-radius:               .5rem;
$border-radius-xs:            .1875rem;
$border-radius-sm:            .2rem;
$border-radius-lg:            1rem;

$box-shadow:                  0px 4px 16px rgba(0, 0, 0, 0.04);
$box-shadow-sm:               0 8px 28px rgba(0, 0, 0, 0.08);
$box-shadow-lg:               0 4px 24px rgba(0, 0, 0, 0.12);

$link-decoration: none;
$enable-cssgrid: false;
