
@mixin custom-scrollbar {
    &::-webkit-scrollbar {
        width: .375rem;
        height: .375rem;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-gray-100;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $color-gray-1000;
        border-radius: 0.25rem;
    }
}

@mixin no-scrollbar {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 0;
    }
}
