@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700&display=swap');

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/maps";

$theme-colors: (
    "primary":    $color-brand,
    "secondary":  $color-blue,
    "success":    $color-yellow,
    "info":       $color-blue,
    "warning":    $color-brand,
    "danger":     $color-orange,
    "light":      $color-gray-200,
    "dark":       $color-gray-1000
);

@import "../vuexy/bootstrap-extended/variables";
@import "../vuexy/components/variables";
@import "../vuexy/components/variables-dark";

@import "~bootstrap/scss/reboot";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/containers";
@import "../vuexy/bootstrap-extended/forms";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/breadcrumb";
@import "../vuexy/bootstrap-extended/breadcrumb";
@import "~bootstrap/scss/card";
@import "../vuexy/bootstrap-extended/card";
@import "../vuexy/bootstrap-extended/helper";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/pagination";
@import "../vuexy/bootstrap-extended/pagination";

@import "~dropzone/dist/dropzone.css";
@import "../vuexy/plugins/forms/form-file-uploader";

@import '../vuexy/core/mixins/alert';
@import '../vuexy/core/mixins/hex2rgb';

@import "../../IconsFont/IconFont";
@include webfont-init();

@import "kit/Breadcrumbs/Breadcrumbs";
@import "kit/Form/Form";

body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
    color: $color-text;
}

.page {
    min-width: 375px;
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__main {
        flex: 1 0 auto;
    }

    &__footer {
        flex-shrink: 0;
    }
}

input:is([type="button"],
[type="submit"],
[type="reset"]),
input[type="file"]::file-selector-button,
button {
    color: $color-black;
}