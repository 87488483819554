
                                @import "./src/kit/styles/config/_include";
                            
.footer {
    margin-bottom: 1.875rem;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        padding: 2.625rem 3.75rem 2.5625rem;
        background: $color-black-soft url(./img/footer-image.svg) center / cover no-repeat;
        color: $color-white;
        border-radius: 0.625rem;

        @include media-breakpoint-down(xxl) {
            padding-left: 2.5rem;
            padding-right: 2.5rem;
        }

        @include media-breakpoint-down(xl) {
            flex-wrap: wrap;
        }

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            background-image: none;
            padding: 1.875rem 0.625rem;
        }
    }

    &__logo {
        flex: 0 0 auto;
        align-self: center;
        width: 16.25rem;
        height: 2.0625rem;
        margin-right: 2.5rem;
        background: url(./img/logo-white.svg) center / contain no-repeat;

        @include media-breakpoint-down(xxl) {
            margin-right: 1.25rem;
        }

        @include media-breakpoint-down(xl) {
            flex-basis: 100%;
            margin: 0 0 3rem;
        }

        @include media-breakpoint-down(lg) {
            flex-basis: auto;
            margin-bottom: 1.875rem;
        }
    }

    &__info-block {
        flex-shrink: 0;
        padding: 0 0.625rem;

        &--service {
            @include media-breakpoint-down(lg) {
                margin-bottom: 1.5rem;
            }
        }

        &--partnership {
            @include media-breakpoint-down(lg) {
                margin-bottom: 1.875rem;
            }
        }

        @include media-breakpoint-down(xl) {
            text-align: center;
        }
    }

    &__subscription {
        margin: 0 0 0 3.125rem;

        @include media-breakpoint-down(xxl) {
            margin-left: 1.25rem;
        }

        @include media-breakpoint-down(lg) {
            margin-left: 0;
        }
    }

}

.info-block {
    &__title {
        font-size: $h6-font-size;
        font-weight: $font-weight-semibold;
        margin: 0 0 2.25rem;
        color: $color-white;

        @include media-breakpoint-down(xl) {
            margin-bottom: 1rem;
        }
    }

    &__list {
        @include list-unstyled;

        @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__item:not(:last-child) {
        margin-bottom: 1.125rem;
    }

    &__link {
        color: inherit;
    }
}

.subscription-form {
    @include media-breakpoint-down(lg) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-size: $h6-font-size;
        line-height: $text-line-height-xl;
        font-weight: $font-weight-semibold;
        margin: 0 0 1rem;
        color: $color-white;
    }

    &__field {
        display: flex;
        margin-bottom: 0.75rem;
    }

    &__input[id="email"] {
        padding: 0.75rem 1.125rem;
        background: $color-white;
        border: none;
        border-radius: 0.75rem 0.1875rem 0.1875rem 0.75rem;
        width: 18.375rem;
        margin-right: 0.375rem;
        font-weight: $font-weight-light;
        line-height: $text-line-height-xl;
        color: $color-black-soft;

        &::placeholder {
            color: $color-black-soft;
        }

        @include media-breakpoint-down(xxl) {
            width: 15.625rem;
        }

        @include media-breakpoint-down(lg) {
            width: 16.4375rem;
        }
    }

    &__submit {
        padding: 0.4375rem 0.75rem;
        width: 3.375rem;
        border: none;
        border-radius: 0.1875rem 0.75rem 0.75rem 0.1875rem;
        background: $color-yellow;

        .icon-mail {
            font-size: 1.5rem;
        }
    }

    &__label[for="subscription-agree"] {
        font-size: $text-size-sm;
        line-height: $text-line-height-xl;
        display: block;
        position: relative;
        padding: 0.1875rem 0 0.1875rem 1.875rem;
        cursor: pointer;
        margin-bottom: 2.25rem;

        @include media-breakpoint-down(lg) {
            margin-bottom: 1.875rem;
        }
    }

    &__checkbox-indicator {
        position: absolute;
        left: 0.125rem;
        top: 0.125rem;
        content: "";
        width: 1.25rem;
        height: 1.25rem;
        background-color: $color-yellow;
        border-radius: 0.1875rem;
        overflow: hidden;

        .icon-checkbox-on {
            display: none;
        }
    }

    &__input[id="subscription-agree"]:checked + .subscription-form__checkbox-indicator {
        background-color: transparent;

        .icon-checkbox-on {
            display: block;
            font-size: 1.3125rem;
            color: $color-yellow;
        }
    }

    &__agreement-text {
        color: $color-gray-900;
    }

    &__processing-text a {
        color: inherit;
        text-decoration: underline;
    }

    &__info-link {
        display: block;
        font-size: $text-size-sm;
        line-height: 2;
        color: inherit;

        @include media-breakpoint-down(lg) {
            text-align: center;
            padding-left: 2.9375rem;
            padding-right: 2.9375rem;
        }
    }
}
