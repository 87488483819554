
                                @import "./src/kit/styles/config/_include";
                            
.header-modal {
    &,
    .modal-overlay {
        top: 50px;
    }
    .modal {
        &-close {
            display: none;
        }
        &-dialog {
            height: 100%;
            width: 100%;
            max-width: unset;
            margin: 0;
            border-radius: 0;
        }
        &-content {
            padding: 1.5rem;
            height: 100%;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
    }
    &__link {
        font-size: .875rem;
        padding: .75rem 1.125rem;
        border-radius: .75rem;
        color: $color-black-soft;
        display: flex;
        align-items: center;
        &:hover {
            color: $color-brand;
        }
        &:not(:last-child) {
            margin-bottom: .75rem;
        }
        &-black {
            color: $color-white;
            background-color: $color-black-soft;
        }
        span {
            font-size: .875rem;
            margin-left: .5rem;
        } 
    }
}

.header__menu {
    &-close {
        display: none;
    }
    &-toggle.active {
        .header__menu-burger {
            display: none;
        }
        .header__menu-close {
            display: block;
        }
    }
}