
                                @import "./src/kit/styles/config/_include";
                            
.header__menu-catalog {
    position: relative;
    z-index: 99;
    &:hover {
        padding-bottom: 1.25rem;
        margin-bottom: -1.25rem;
        button {
            background-color: $color-brand;
        }
        .dropdown-catalog {
            max-height: 100%;
        }
        + .dropdown-catalog__overlay {
            display: block;
        }
    }
}

.dropdown-catalog {
    width: 100%;
    height: auto;
    max-height: 0;
    z-index: 99;
    position: fixed;
    left: 50%;
    top: auto;
    overflow: hidden;
    transform: translateX(-50%);
    transition: all .4s ease;
    &__logo {
        width: 100%;
        height: 1.875rem;
        margin-bottom: 1.875rem;
        background: url(../../img/logo-black.svg) left center / contain no-repeat;
        grid-area: logo;
    }
    &__overlay {
        position: fixed;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 98;
        top: 4.9375rem;
        background-color: rgba($color-black-soft, .6);
        display: none;
    }
    &__card {
        background-color: $color-white;
        border-radius: .625rem;
        padding: 1.25rem;
        margin-top: 2.5rem;
        width: 100%;
        display: grid;
        grid-template:
            'logo img' 1.875rem
            'cats img' auto/auto 16rem;
        grid-gap: 1.875rem 0;
    }
    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &__image {
        min-height: 12rem;
        padding-left: 16%;
        grid-area: img;
        background: url(./img/header-catalog-dropdown.png) top right / contain no-repeat;
    }
    .dropdown-catalog {
        &__list {
            list-style-type: none;
            grid-area: cats;
            margin: 0;
        }
        &__item {
            margin-right: 1.25rem;
            margin-bottom: 1.75rem;
        }
    }
    .dropdown-catalog__category {
        align-items: self-start;
        height: 100%;
        border-radius: .625rem;
        border-color: $color-gray-500;
        margin: 0;
        box-shadow: none;
        &__image {
            width: 7.5rem;
            height: 7.5rem;
            border-radius: 50%;
            background-color: $color-white;
            border: 1px solid $color-gray-700;
            object-position: center;
            object-fit: contain;
        }
        &__name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 2.625rem;
        }
        &.card {
            align-items: center;
            padding: 1.8125rem .625rem;
        }

        &__image {
            display: block;
            border-radius: 50%;
            margin-bottom: .75rem;
            background: $color-gray-300;
            object-fit: contain;
        }

        &__name {
            text-align: center;
            color: $color-black-soft;
            max-width: 7.5rem;
            font-size: .875rem;
            font-weight: $font-weight-medium;
            line-height: $text-line-height-xl;
            margin: 0;
            transition: color 200ms ease;
        }
    }
}

.header__menu-search {
    margin-left: auto;

    &_icon {
        font-size: 2.25rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        cursor: pointer;
        &:before {
            margin-top: -0.65rem;
            color: $color-black-soft;
        }
    }

    &_form {
        display: flex;
        align-items: center;
        width: 100%;

        input {
            font-size: 1.25rem;
            width: 100%;
            padding: 0;
            border: none;
            box-shadow: none;
            outline: none;
            padding: .75rem 0;
            line-height: 1.5rem;
        }

        button {
            color: $color-white;
            text-align: center;
            padding: 0.75rem 0;
            font-size: 1rem;
            line-height: 1.5;
            font-weight: 500;
            border: none;
            border-radius: 0.625rem;
            width: 100%;
            transition: all 0.2s ease;
            margin-left: 1.5rem;
            width: 22rem;
            display: flex;
            align-items: center;
            justify-content: center;
            > span {
                display: flex;
                font-size: 1.25rem;
                width: 1.5rem;
                margin-left: .5rem;
            }
        }
    }

    .dropdown-catalog {
        margin-top: -.5rem;
        &__card {
            display: flex;
            margin-top: 3rem;
        }
    }
    @include media-breakpoint-down(lg) {
        + .dropdown-catalog__overlay {
            top: 3.75rem;

        }
    }

    @include media-breakpoint-down(md) {
        button {
            font-size: 0;
            width: 4rem;
            margin-left: 0;
            > span {
                font-size: 1.5rem;
                margin: 0;
            }
        }
    }
}