
                                @import "./src/kit/styles/config/_include";
                            
.header {
    background-color: $color-white;

    @include media-breakpoint-up(xl) {
        position: fixed;
        width: 100%;
        z-index: 99;

        + * {
            margin-top: 5rem;
        }
    }

    @include media-breakpoint-up(lg) {
        border-bottom: $border-width-sm solid $color-gray-100;
    }
    @include media-breakpoint-down(lg) {
        > .container {
            padding: 0 1.5rem;
        }
    }

    &__wrapper {
        display: flex;
        align-items: center;
        padding: 1.25rem 0 1.1875rem;

        @include media-breakpoint-down(lg) {
            justify-content: space-between;
            padding: 0.8125rem 0 0.875rem;
        }
    }

    &__menu-toggle {
        display: none;
        transition: all .2s;

        @include media-breakpoint-down(lg) {
            display: block;
            padding: 0;
            width: 1.5rem;
            height: auto;
            border: none;
            background: transparent;
            color: $color-black-soft !important;

            .header__menu-icon {
                font-size: 1.5rem;
                color: $color-black-soft;
            }
        }

        &:hover,
        &:focus {
            .header__menu-icon {
                color: $color-brand;
            }
        }
    }

    &__back-link {
        display: none;
        transition: all .2s;

        .icon-arrow-left {
            font-size: 1.5rem;
            color: $color-black-soft;
        }

        &:hover,
        &:focus {
            .icon-arrow-left {
                color: $color-brand;
            }
        }
    }

    &__logo {
        display: block;
        flex-shrink: 0;
        width: 9.75rem;
        height: 1.25rem;
        margin-right: 1.875rem;
        background: url(./img/logo-black.svg) center / contain no-repeat;

        @include media-breakpoint-down(lg) {
            margin-right: 0;
        }
    }

    &__menu {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__catalog-toggle {
        display: flex;
        align-items: center;
        margin-right: 1.875rem;
        padding: 0.6875rem 1.125rem;
        background-color: $color-black;
        color: $color-white;
        font-weight: $font-weight-semibold;
        border-radius: 0.3125rem;
        border: none;
        transition: all .2s;

        .icon-list {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 1.125rem;
            height: 1.125rem;
            margin-right: 0.375rem;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: $color-brand;
        }
    }

    &__user-nav {
        display: flex;
        position: relative;

        @include media-breakpoint-down(lg) {
            margin-left: 0;
        }
    }
}

.site-nav {
    &__list {
        @include list-unstyled();
        display: flex;
        @include media-breakpoint-down(xl) {
            display: none;
        }
    }

    &__item {
        margin-right: 1.875rem;
    }

    &__link {
        font-weight: $font-weight-medium;
        font-size: $text-size-lg;
        color: $color-gray-1000;

        transition: all .2s;

        &:hover,
        &:focus,
        &:active {
            color: $color-brand;
        }
    }
}

.user-nav {
    &__text {
        @include media-breakpoint-down(lg) {
            display: none;
        }
    }

    &__link {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;
        color: $color-black-soft;
        z-index: 9;
        padding: 0 0 0 1.125rem;

        @include media-breakpoint-up(xl) {
            width: 13rem;
        }

        transition: all .2s;

        .user-nav__icon {
            display: inline-flex;
            font-size: 1.25rem;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-up(lg) {
                margin-left: 0.75rem;
            }
        }

        @include media-breakpoint-up(lg) {
            &:not(:last-child) {
                &::after {
                    content: '';
                    display: block;
                    height: 1.6875rem;
                    margin: 0 .625rem;
                    border-right: 1px solid $color-gray-700;
                }
            }
        }

        &-signedin {
            .icon-sign-out {
                font-size: 1.25rem;
            }
            &::after {
                display: none !important;
            }
            @include media-breakpoint-down(xl) {
                display: none;
            }
        }

        &-mobile {
            display: none;

            @include media-breakpoint-down(xl) {
                display: block;
            }
        }

        &:hover,
        &:focus,
        &:active {
            color: $color-brand;
        }
    }

    &__info {
        font-size: .75rem;
        margin-right: .75rem;
        font-weight: $font-weight-medium;
    }

    &__nickname {
        margin-bottom: .25rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    &__cashback {
        color: $color-gray-1000;
    }

    &__photo {
        width: 2.275rem;
        height: 2.275rem;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: .75rem;
        span {
            font-size: 2.25rem;
        }
    }

    @include media-breakpoint-down(xl) {
        &__info,
        .user-nav__link-signedin .icon-sign-out {
            display: none;
        }
        &__photo {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0;
            span {
                font-size: 1.5rem;
            }
        }
    }
}
