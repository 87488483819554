.breadcrumbs {
    padding: 0;
    margin-bottom: 1.25rem;

        @include media-breakpoint-down(lg) {
            display: none;
        }

        &__item {
            font-size: .875rem;
            line-height: $text-line-height-xxxl;
            &, * {
                color: $color-gray-900;
            }

            + .breadcrumbs__item {
                padding-left: .25rem!important;
                &::before {
                    color:  $color-gray-900;
                    padding-right: .25rem!important;
                }
            }
        }

        a {
            &:hover {
                color: $color-brand;
            }
        }
}
