@use "sass:math";

@function px-to-rem-base($px) {
    @if $px == 0 {
        @return $px;
    } @else {
        @return math.div($px, $font-size-html) * 1rem;
    }
}

@function px-to-rem($paddingPixels) {
    $paddingRems: ();
    @each $padding in $paddingPixels {
        $paddingRems: append($paddingRems, px-to-rem-base($padding));
    }
    @return $paddingRems;
}

// Typography
//
$font-family-sans-serif: 'Inter', 'Montserrat', helvetica, arial, sans-serif;
$font-family-base: $font-family-sans-serif;

$font-size-html: 16px;
$font-size-root: 1rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: bold;

$font-weight-base: $font-weight-normal;

$font-size-base: 1rem;
$font-size-lg: 1rem;
$font-size-sm: .75rem;
$font-size-xs: px-to-rem(11px);

$line-height-xxxl: 1.7;
$line-height-xxl: 1.6;
$line-height-xl: 1.5;
$line-height-lg: 1.4;
$line-height-md: 1.3;
$line-height: 1.1;
$line-height-sm: 1;
$line-height-base: $line-height;

$h1-font-size: px-to-rem(70px);
$h2-font-size: px-to-rem(50px);
$h3-font-size: px-to-rem(40px);
$h4-font-size: px-to-rem(30px);
$h5-font-size: px-to-rem(24px);
$h6-font-size: px-to-rem(20px);

$h1-line-height: $line-height-sm;
$h2-line-height: $line-height-sm;
$h3-line-height: $line-height-sm;
$h4-line-height: $line-height-sm;
$h5-line-height: $line-height-sm;
$h6-line-height: $line-height-sm;

$headings-margin-bottom: 1rem;
$headings-font-family: null;
$headings-font-style: null;
$headings-font-weight: $font-weight-semibold;
$headings-line-height: $line-height-sm;
$headings-color: $color-black-soft;

$text-size-base: $font-size-base;
$text-size-lg: $font-size-lg;
$text-size-sm: $font-size-sm;
$text-size-xs: $font-size-xs;

$text-margin-bottom: 0;
$text-font-family: null;
$text-font-style: null;
$text-font-weight: normal;

$text-line-height: $line-height-base;
$text-line-height-xxxl: $line-height-xxxl;
$text-line-height-xxl: $line-height-xxl;
$text-line-height-xl: $line-height-xl;
$text-line-height-lg: $line-height-lg;
$text-line-height-md: $line-height-md;
$text-line-height-sm: $line-height-sm;

