
                                @import "./src/kit/styles/config/_include";
                            
.authorize-modal {
    * {
        font-family: $font-family-base;
        transition: all .2s ease;
    }
    .modal {
        &-dialog {
            width: 45%;
            max-width: 45rem;
            min-width: 34.375rem;
            height: 100%;
            margin-left: auto;
            border-radius: 0;
            overflow: hidden;
            padding-left: 3.75rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-right: 0;
            @include media-breakpoint-down(sm) {
                width: 100%;
                min-width: 100%;
                align-items: flex-start;
                padding: 5.625rem 1rem 1rem;
            }
        }
        &-close {
            font-size: .75rem;
            top: 1.875rem;
            right: 5.625rem;
            position: absolute;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            background-color: #f3f5f9;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &:hover {
                opacity: .8;
            }
            @include media-breakpoint-down(sm) {
                right: 1rem;
            }
        }
        &-content {
            width: 26rem;
            max-width: 100%;
            border: none;
        }
    }

    &__logo {
        display: block;
        width: 156px;
        height: 1.25rem;
        margin-right: 1.875rem;
        background: url(../Header/img/logo-black.svg) center / contain no-repeat;
        margin-bottom: 2rem;
    }

    &__title {
        color: #081142;
        font-size: 1.875rem;
        line-height: 1;
        font-weight: 500;
        max-width: 18.75rem;
        margin: 0 0 1.25rem;
    }
}

.auth-step {
    &-1 {
        margin-top: 3.75rem;
    }
    &-arrow {
        display: block;
        line-height: 1;
        font-size: 1.25rem;
        margin-bottom: 2rem;
        cursor: pointer;
    }
}

.auth-label {
    font-size: .75rem;
    line-height: 1.5;
    margin-bottom: .375rem;
    &__sent {
        font-size: .875rem;
        margin-bottom: 1.375rem;
        line-height: 1.8;
        span {
            font-weight: 500;
            color: $color-black;
            white-space: nowrap;
        }
    }
    &__repeat {
        font-size: .875rem;
        margin-top: -.5rem;
        margin-bottom: 2rem;
        span {
            font-weight: 500;
            color: $color-black;
        }
    }
}

.auth-inputs {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.875rem;
}

.auth-input {
    height: 44px;
    background: $color-white;
    border: 1px solid #DEDEE5;
    border-radius: .1875rem;
    padding: .75rem .625rem;
    font-weight: 500;
    font-size: .875rem;
    color: $color-black;
    outline: none;
    &__phone {
        flex-grow: 1;
        color: #C0C4CD;
        &:focus {
            color: $color-black;
        }
    }
    &__phone-code {
        width: 5.375rem;
    }
    &__code {
        width: 250px;
        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
    &__error {
        width: 100%;
        display: block;
        font-size: .875rem;
        color: $color-brand;
        margin-top: .5rem;
        display: none;
    }
    & + & {
        margin-left: .5rem;
    }
    &:focus {
        border-color: $color-orange;
    }
    &.active {
        color: $color-black;
    }
    &.error {
        border-color: $color-brand;
        background: rgba($color-brand, .2);
    }
    &.error + &__error {
        display: block;
    }
}

.auth-button {
    background: #E8ECED;
    font-size: .875rem;
    font-weight: 600;
    height: 2.8125rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .3125rem;
    margin-bottom: 1.875rem;
    border: none;
    outline: none !important;
    &:hover {
        background: $color-black-soft;
        color: $color-white;
    }
}

.auth-timer {
    white-space: nowrap;
}

.auth-agreement {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    max-width: 20.75rem;
    &__icon {
        color: $color-white;
        font-size: .5rem;
        display: none;
    }
    &__input {
        display: none;
    }
    &__label {
        width: 1.25rem;
        height: 1.25rem;
        flex-shrink: 0;
        display: flex;
        border-radius: .375rem;
        background: #E1E9EB;
        align-items: center;
        justify-content: center;
        margin-right: .5rem;
    }
    &__input:checked + &__label {
        background: #0E0D26;
    }
    &__input:checked + &__label &__icon {
        display: block;
    }
    &__text {
        color: #7D7C93;
        font-size: .75rem;
        a {
            color: $color-black;
            text-decoration: underline;
        }
        &:hover {
            &, a {
                color: $color-brand;
            }
        }
    }
}