
                                @import "./src/kit/styles/config/_include";
                            
/**************************\
Basic Modal Styles
\**************************/

.modal {
    font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif;
}

  
.modal {
    display: none;
}

.modal.is-open {
    display: block;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color-black-soft, .6);
    backdrop-filter: blur(.75rem);
}

.modal-dialog {
    background-color: $color-white;
    max-height: 100vh;
    border-radius: .25rem;
    overflow-y: auto;
    box-sizing: border-box;
}

.modal-close {
    background: transparent;
    border: 0;
}

.modal-content {
    margin-top: 2rem;
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, .8);
    border: none;
    overflow: auto;
    @include custom-scrollbar();
    @include media-breakpoint-down(sm) {
        margin: 0;
    }
}

/**************************\
    Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
        to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
        to { opacity: 0; }
}

@keyframes mmslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
    display: none;
}

.micromodal-slide.is-open {
    display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform;
}