// Color system
//
$color-white:             #FFFFFF;

$color-gray-100:          #EAEAEA;
$color-gray-200:          #F2F2F2;
$color-gray-300:          #F9F9F9;
$color-gray-400:          #E0E0E0;
$color-gray-500:          #E6E6E6;
$color-gray-600:          #EFF4F5;
$color-gray-700:          #D9E3E4;
$color-gray-800:          #E9E9E9;
$color-gray-900:          #A0A9AB;
$color-gray-1000:         #969FAB;
$color-gray-1100:         #9594A5;
$color-gray-1200:         #54535F;
$color-gray-1300:         #6E6B7B;


$color-black:             #000000;
$color-black-soft:        #1A2020;

$color-yellow:            #FCFF73;
$color-orange:            #FFCA64;
$color-orange-dark:       #F5B333;
$color-violet:            #9B51E0;
$color-dark-blue:         #4200FF;

$color-brand:             #FF3A61;
$color-blue:              #699DEE;
$color-red:               $color-brand;
$color-green:            #6FCF97;

$color-page:              $color-gray-300;
$color-text:              $color-black-soft;

$theme-colors: (
    "primary":    $color-brand,
    "secondary":  $color-blue,
    "success":    $color-yellow,
    "info":       $color-blue,
    "warning":    $color-brand,
    "danger":     $color-orange,
    "light":      $color-gray-200,
    "dark":       $color-gray-1000
);

$success: $color-yellow;
$primary: $color-brand;

$nav-tabs-link-active-color: $color-brand;

$input-border-color: $color-gray-400;
$input-focus-border-color: $primary;

$status_colors: (
    'created': $color-violet,
    'process': $color-blue,
    'ready': $color-green,
    'rejected': $color-red
);