
                                @import "./src/kit/styles/config/_include";
                            
.personal-modal {
    &,
    .modal-overlay {
        top: 50px;
        background-color: transparent;
    }
    .modal {
        &-close {
            display: none;
        }
        &-dialog {
            height: 100%;
            width: 100%;
            max-width: unset;
            margin: 0;
            border-radius: 0;
        }
        &-content {
            padding: 1.5rem;
            height: 100%;
        }
    }
    &__list {
        display: flex;
        flex-direction: column;
        padding-bottom: .5rem;
        margin-bottom: .5rem;
        &:not(:last-child) {
            border-bottom: 1px solid $color-gray-700;
        }
    }
    &__link {
        font-size: .75rem;
        padding: .75rem 1.125rem;
        border-radius: .75rem;
        color: $color-black-soft;
        display: flex;
        align-items: center;
        line-height: 1.3;
        &:hover {
            color: $color-brand;
        }
        &:not(:last-child) {
            margin-bottom: .5rem;
        }
        &-black {
            color: $color-white;
            background-color: $color-black-soft;
            justify-content: space-between;
            width: 100%;
        }
    }
    &__icon {
        font-size: 1.25rem;
        margin-right: .75rem;
        display: flex;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
    } 
    &__info {
        display: flex;
        flex-direction: column;
        * {
            font-weight: $font-weight-medium;
        }
    }
    &__user {
        padding-bottom: 0;
    }
    &__cashback {
        color: $color-gray-900;
    }
    .user-nav__photo {
        margin-right: .75rem;
    }
    @include media-breakpoint-up(xl) {
        &__link {
            &:not(:last-child) {
                margin-bottom: 0;
                padding: .5rem 1.125rem;
            };
        }
    }
}

.personal-dropdown {
    max-width: 13rem;
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 3.5rem;
    width: 13.125rem;
    background: $color-white;
    border-radius: .3125rem;
    box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
    z-index: 8;
    display: none;

    &-trigger {
        &:hover {
            .personal-dropdown {
                display: block;
            }
            .user-nav__link .icon-sign-out {
                opacity: 0;
            }
        }
    }

    .personal-modal {
        &__list {
            padding-top: .5rem;
            margin-top: .5rem;
            border-top: 1px solid $color-gray-700;
            &:not(:last-child) {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
        &__signout {
            padding: 0;
            margin: .5rem 0 0;
            .personal-modal__user {
                margin-bottom: 0;
                padding: .75rem 1rem .75rem 1.25rem;
            }
        }
        &__link {
            border-radius: 0;
            padding: .5rem 1rem .5rem 1.25rem;
            color: $color-gray-1000;
            font-weight: $font-weight-medium;
            &:hover {
                background-color: $color-gray-600;
            }
            .personal-modal__icon {
                color: $color-black-soft;
            }
        }
    }

    @include media-breakpoint-down(xl) {
        display: none !important;
    }
}

.personal__menu {
    &-close {
        display: none;
        width: 1.5rem;
        height: 1.5rem;
        align-items: center;
        justify-content: center;
        font-size: 1.25rem;
    }
    &-toggle.active {
        .personal__menu-open {
            display: none;
        }
        .personal__menu-close {
            display: flex;
        }
    }
}