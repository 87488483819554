

// Grid
//
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1400px,
    xxxl: 1600px,
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 976px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1452px,
);


$container-padding-x: 16px;

